<template>
  <div class="fc-map__page-body">
    <div class="body-1 my-3">
      <div class="d-flex flex-wrap justify-end mt-3">
        <!-- <v-btn :loading="loading" text class="ml-3" @click="request('log')">
          {{ $t('actions.requestReport') }}
        </v-btn> -->
      </div>
      <v-alert v-if="!log.length" type="info" outlined>
        {{ $t("success.noReport") }}
      </v-alert>
      <v-simple-table v-else>
        <thead>
          <tr>
            <th>{{ $t("log.time") }}</th>
            <th>{{ $t("log.speed") }}</th>
            <th>{{ $t("log.start_deg") }}</th>
            <th>{{ $t("log.deg") }}</th>
            <th>{{ $t("log.bar") }}</th>
            <th>{{ $t("log.vlt") }}</th>
            <th>{{ $t("log.req_status") }}</th>
            <th>{{ $t("log.cur_status") }}</th>
            <th>{{ $t("log.err_type") }}</th>
            <th>{{ $t("log.dir") }}</th>
            <th>{{ $t("log.wrt") }}</th>
            <th>{{ $t("log.frt") }}</th>
            <th>{{ $t("log.stop") }}</th>
            <th>{{ $t("log.fwd_cont_relay") }}</th>
            <th>{{ $t("log.rev_cont_relay") }}</th>
            <th>{{ $t("log.fwd_coil_relay") }}</th>
            <th>{{ $t("log.rev_coil_relay") }}</th>
            <th>{{ $t("log.speed_coil_relay") }}</th>
            <th>{{ $t("log.wtr_relay") }}</th>
            <th>{{ $t("log.frt_relay") }}</th>
            <th>{{ $t("log.eg1_relay") }}</th>
            <th>{{ $t("log.eg2_relay") }}</th>
            <th>{{ $t("log.gnss_ps") }}</th>
            <th>{{ $t("log.barrier_side") }}</th>
            <th>{{ $t("log.time_change") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in log" :key="row.key">
            <td>{{ row.time }}</td>
            <td>{{ row.speed }}</td>
            <td>{{ row.start_deg }}</td>
            <td>{{ row.deg }}</td>
            <td>{{ row.bar }}</td>
            <td>{{ row.vlt }}</td>
            <td>{{ row.req_status }}</td>
            <td>{{ row.cur_status }}</td>
            <td>{{ row.err_type }}</td>
            <td>{{ row.dir }}</td>
            <td>{{ row.wtr }}</td>
            <td>{{ row.frt }}</td>
            <td>{{ row.stop }}</td>
            <td>{{ row.fwd_cont_relay }}</td>
            <td>{{ row.rev_cont_relay }}</td>
            <td>{{ row.fwd_coil_relay }}</td>
            <td>{{ row.rev_coil_relay }}</td>
            <td>{{ row.speed_coil_relay }}</td>
            <td>{{ row.wtr_relay }}</td>
            <td>{{ row.frt_relay }}</td>
            <td>{{ row.eg1_relay }}</td>
            <td>{{ row.eg2_relay }}</td>
            <td>{{ row.gnss_ps }}</td>
            <td>{{ row.barrier_side }}</td>
            <td>{{ row.time_change }}</td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>
    <v-row v-if="isFC" class="mt-3">
      <v-col class="d-flex flex-wrap ustify-end">
        <template>
          <v-btn :loading="loading" class="mr-3" text @click="request">{{
            $t("actions.requestLog")
          }}</v-btn>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import generalFormMixin from "~/components/mixins/generalFormMixin";

export default {
  props: ["id", "tab"],
  mixins: [generalFormMixin],
  data() {
    return {
      log: [],
    };
  },
  computed: {
    items() {
      return this.$store.getters["rtu/items"];
    },
    item() {
      return this.items.find((item) => item.id === this.id);
    },
    isFC() {
      if (this.item.manufacturer === "FC") {
        return true;
      } else {
        return false;
      }
    },
    report() {
      const out = [];
      if (this.item && this.item.history) {
        return this.item.report;
      }
      return out;
    },
  },
  methods: {
    request() {
      this.setLoading(true);
      return (
        Promise.resolve()
          .then(() =>
            this.$store.dispatch("fetch/fetch", {
              path: `rtu.logRequest`,
              data: { id: this.id },
            })
          )
          .then(() => {
            this.$swal({
              icon: "success",
              text: this.$t(`success.logRequested`),
            });
          })
          .then(() =>
              new Promise((resolve) => {
                this._timeout && clearTimeout(this._timeout);
                this._timeout = setTimeout(resolve, 1000);
              })
          )
          .then(() =>
            this.$store.dispatch("fetch/fetch", {
              path: `rtu.getLogRequest`,
              data: { id: this.id },
            })
          )
          .then((response) => {
            if (response.data) {
              this.log = response.data.log
            }
          })
          .catch(this.handleError)
          .finally(() => {
            this.setLoading(false);
          })
      );
    },
  },
};
</script>
